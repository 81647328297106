import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import Slider from '@emcasa/ui-dom/components/Slider';
import View from '@emcasa/ui-dom/components/View';
import { MarkerLabel } from './slider.js';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "slider"
    }}>{`Slider`}</h1>
    <p>{`Slider component.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<View m={4}>\n  <Slider\n    onChange={console.log}\n    range={[0, 1]}\n    initialValue={{\n      min: 0,\n      max: 1,\n    }}\n  >\n    <Slider.Marker name=\"min\" />\n    <Slider.Marker name=\"max\" />\n  </Slider>\n</View>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      Slider,
      View,
      MarkerLabel
    }} mdxType="Playground">
  <View m={4} mdxType="View">
    <Slider onChange={console.log} range={[0, 1]} initialValue={{
          min: 0,
          max: 1
        }} mdxType="Slider">
      <Slider.Marker name="min" />
      <Slider.Marker name="max" />
    </Slider>
  </View>
    </Playground>
    <h2 {...{
      "id": "fixed-width"
    }}>{`Fixed width`}</h2>
    <Playground __position={1} __code={'<View m={4}>\n  <Slider\n    width={200}\n    onChange={console.log}\n    range={[0, 1]}\n    initialValue={{\n      min: 0,\n      max: 1,\n    }}\n  >\n    <Slider.Marker name=\"min\" />\n    <Slider.Marker name=\"max\" />\n  </Slider>\n</View>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      Slider,
      View,
      MarkerLabel
    }} mdxType="Playground">
  <View m={4} mdxType="View">
    <Slider width={200} onChange={console.log} range={[0, 1]} initialValue={{
          min: 0,
          max: 1
        }} mdxType="Slider">
      <Slider.Marker name="min" />
      <Slider.Marker name="max" />
    </Slider>
  </View>
    </Playground>
    <h2 {...{
      "id": "track-and-marker-styles"
    }}>{`Track and marker styles`}</h2>
    <Playground __position={2} __code={'<View mb=\"25px\">\n  <Slider\n    range={[0, 1]}\n    initialValue={1 / 2}\n    trackProps={{ bg: \'lightGrey\' }}\n  >\n    <Slider.Marker trackProps={{ height: 4, bg: \'pink\' }} />\n  </Slider>\n</View>\n<View mb=\"25px\">\n  <Slider\n    range={[0, 1]}\n    initialValue={[1 / 4, 3 / 4]}\n    trackProps={{ bg: \'lightGrey\' }}\n  >\n    <View\n      bg=\"pink\"\n      width={25}\n      height={25}\n      borderRadius={5}\n      trackProps={{ bg: \'pink\' }}\n    />\n    <View\n      bg=\"blue\"\n      width={25}\n      height={25}\n      borderRadius={5}\n      trackProps={{ bg: \'blue\' }}\n    />\n  </Slider>\n</View>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      Slider,
      View,
      MarkerLabel
    }} mdxType="Playground">
  <View mb="25px" mdxType="View">
    <Slider range={[0, 1]} initialValue={1 / 2} trackProps={{
          bg: 'lightGrey'
        }} mdxType="Slider">
      <Slider.Marker trackProps={{
            height: 4,
            bg: 'pink'
          }} />
    </Slider>
  </View>
  <View mb="25px" mdxType="View">
    <Slider range={[0, 1]} initialValue={[1 / 4, 3 / 4]} trackProps={{
          bg: 'lightGrey'
        }} mdxType="Slider">
      <View bg="pink" width={25} height={25} borderRadius={5} trackProps={{
            bg: 'pink'
          }} mdxType="View" />
      <View bg="blue" width={25} height={25} borderRadius={5} trackProps={{
            bg: 'blue'
          }} mdxType="View" />
    </Slider>
  </View>
    </Playground>
    <h2 {...{
      "id": "marker-labels"
    }}>{`Marker labels`}</h2>
    <Playground __position={3} __code={'<View flexDirection=\"column\">\n  <Slider range={[0, 100]} initialValue={[0, 100]}>\n    <Slider.Marker\n      renderLabel={({ value }) => (\n        <MarkerLabel>{value.toFixed(2)}</MarkerLabel>\n      )}\n    />\n    <Slider.Marker\n      renderLabel={({ value }) => (\n        <MarkerLabel>{value.toFixed(2)}</MarkerLabel>\n      )}\n    />\n  </Slider>\n</View>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      Slider,
      View,
      MarkerLabel
    }} mdxType="Playground">
  <View flexDirection="column" mdxType="View">
    <Slider range={[0, 100]} initialValue={[0, 100]} mdxType="Slider">
      <Slider.Marker renderLabel={({
            value
          }) => <MarkerLabel mdxType="MarkerLabel">{value.toFixed(2)}</MarkerLabel>} />
      <Slider.Marker renderLabel={({
            value
          }) => <MarkerLabel mdxType="MarkerLabel">{value.toFixed(2)}</MarkerLabel>} />
    </Slider>
  </View>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      